import {useEffect, useCallback} from 'react'
import {webSocketClient} from '@component/websocket/websocket'

export const useWebSocket = (url: string, onMessage: (data: any) => void) => {
    const connect = useCallback(() => {
        console.log('Attempting to connect WebSocket...')
        webSocketClient.initialize({
            url,
            onMessage,
            onError: error => {
                console.error('WebSocket error:', error)
            },
            reconnectAttempts: 3,
            reconnectInterval: 1000,
        })
    }, [url])

    useEffect(() => {
        connect()

        return () => webSocketClient.cleanup()
    }, [])
}
