interface WebSocketOptions {
    url: string
    reconnectAttempts?: number
    reconnectInterval?: number
    onMessage?: (data: any) => void
    onConnect?: () => void
    onDisconnect?: () => void
    onError?: (error: Event) => void
}

class WebSocketClient {
    private static instance: WebSocketClient | null = null
    private ws: WebSocket | null = null
    private reconnectCount: number = 0
    private reconnectTimeout?: NodeJS.Timeout
    private isConnected: boolean = false

    private url: string = ''
    private reconnectAttempts: number = 3
    private reconnectInterval: number = 1000
    private onMessage?: (data: any) => void
    private onConnect?: () => void
    private onDisconnect?: () => void
    private onError?: (error: Event) => void

    private constructor() {
        // 모든 메서드를 생성자에서 bind
        this.connect = this.connect.bind(this)
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.cleanup = this.cleanup.bind(this)
        this.reconnect = this.reconnect.bind(this)
        this.handleWebSocketOpen = this.handleWebSocketOpen.bind(this)
        this.handleWebSocketClose = this.handleWebSocketClose.bind(this)
        this.handleWebSocketError = this.handleWebSocketError.bind(this)
        this.handleWebSocketMessage = this.handleWebSocketMessage.bind(this)
    }

    public static getInstance(): WebSocketClient {
        if (!WebSocketClient.instance) {
            WebSocketClient.instance = new WebSocketClient()
        }
        return WebSocketClient.instance
    }

    public initialize(options: WebSocketOptions) {
        this.url = options.url
        this.reconnectAttempts = options.reconnectAttempts ?? 3
        this.reconnectInterval = options.reconnectInterval ?? 1000
        this.onMessage = options.onMessage
        this.onConnect = options.onConnect
        this.onDisconnect = options.onDisconnect
        this.onError = options.onError

        document.addEventListener('visibilitychange', this.handleVisibilityChange)
        this.connect()
    }

    private handleWebSocketOpen() {
        this.isConnected = true
        this.reconnectCount = 0
        this.onConnect?.()
    }

    private handleWebSocketClose() {
        this.isConnected = false
        this.onDisconnect?.()

        if (this.reconnectCount < this.reconnectAttempts) {
            this.reconnectTimeout = setTimeout(() => {
                this.reconnectCount += 1
                this.connect()
            }, this.reconnectInterval)
        }
    }

    private handleWebSocketError(err: Event) {
        console.log(err)
        this.onError?.(err)
    }

    private handleWebSocketMessage(event: MessageEvent) {
        try {
            const data = JSON.parse(event.data)
            this.onMessage?.(data)
        } catch (err) {
            console.error('Failed to parse WebSocket message:', err)
            this.onError?.(new Event('parse-error'))
        }
    }

    private connect() {
        try {
            this.ws = new WebSocket(this.url)
            this.ws.onopen = this.handleWebSocketOpen
            this.ws.onclose = this.handleWebSocketClose
            this.ws.onerror = this.handleWebSocketError
            this.ws.onmessage = this.handleWebSocketMessage
        } catch (err) {
            console.log(err)
            this.onError?.(err as Event)
        }
    }

    private handleVisibilityChange() {
        if (document.visibilityState === 'visible' && this.ws?.readyState === WebSocket.CLOSED) {
            this.reconnectCount = 0
            this.connect()
        }
    }

    public sendMessage(data: any) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(data))
        } else {
            console.warn('WebSocket is not connected')
        }
    }

    public getConnectionState(): boolean {
        return this.isConnected
    }

    public cleanup() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange)

        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout)
        }

        if (this.ws) {
            this.ws.onopen = null
            this.ws.onclose = null
            this.ws.onerror = null
            this.ws.onmessage = null
            this.ws.close()
            this.ws = null
        }
    }

    public reconnect() {
        this.reconnectCount = 0
        if (this.ws) {
            this.ws.close()
        }
        this.connect()
    }
}

export const webSocketClient = WebSocketClient.getInstance()
